import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import { FaArrowRight } from "react-icons/fa";
import example from "../../assets/image/jpeg/examples.jpg";
 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { device, breakpoints } from "../../utils";
import Newarrow from '../../assets/image/jpeg/enter.svg';



const Bookbutton = styled.button`
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
height: 100%;
padding: 0.8em 2.4em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
font-family: azo-sans-web, sans-serif;
color: #fff;
font-size: 0.7em;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
cursor: pointer;
border:none;
background-color: #00afc9;
color: #fff;
opacity: 0.8;
transition: 0.3s;

&:hover{
  opacity: 1
}
`;





const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
 

}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: #999;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #999;
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;



const Secondtext = styled.div`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 3.2em;

      margin-bottom: 0.4em;
 
      color: #111;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 26px;
   
      }

`;


const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1.6em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 22px;
     text-align:center;
     width: 100%;
 }

`;
const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

export default function Liquidfaq() {
    return (
        <Section py="4" id="faq" bg="#fff" className="pb-md-5 mb pt-md-5">
      <Container className="pb-md-5 mb pt-md-5">
        <Row className="justify-content-center">
          <Col lg="12">
   
            <Iwrap>


<Itext as="h2">More about Liquid Nose Job</Itext>
</Iwrap>
      <Secondtext>
      Frequently Asked Questions

      </Secondtext>
         
          </Col>
        </Row>


        <Row className="">
        <Col
     
     >
           <Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What is a Liquid Nose Job?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
  <b>A liquid nose job is a non-invasive procedure that alters the nose in a matter of moments. Dermal fillers are precisely injected into the nose to achieve a more defined, straightened, and lifted nose, as well as improved harmony and balance to the structure.


</b> <br /> <br />
   
Although the surgery does not dynamically resize the nose, a straighter and more defined nose might give the impression that it is smaller and more in sync with the rest of the face. It can also enhance the frontal viewpoint of the face by contouring the nose and correcting asymmetry or deviation.

        </p>
    </AccordionItemPanel>
</AccordionItem> 




</Accordion>    <br />
         


           <Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What does the liquid nose job in London involve?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        A liquid nose job, often known as "the 10-minute nose job," is a procedure that involves injecting HA-based dermal fillers into the nose to temporarily alter its form.  <br /> <br />

Dermal filler is strategically injected slowly and precisely in little amounts into precise points of the nose using a very fine needle. The hyaluronic acid filler adheres to the area where it's injected, instantly changing the shape of the nose. Because the patient will be awake and not under general anaesthetic, he or she will be able to detect the effects as we deliver the injection.



        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>  

<br />


      
<Accordion allowZeroExpanded className="shadow">

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How long does the Liquid Nose Job take?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        The liquid nose job takes no longer than 15 minutes; 
Patients will witness instant improvements with no downtime, and you will be ready to resume your usual activities or work that same day.

        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>  


<br />


      
<Accordion allowZeroExpanded className="shadow">

<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Does liquid nose job hurt?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Before beginning the treatment, we will administer numbing lotion to the nose (optional) to make your experience as painless as possible. We don't start the treatment until the numbing cream has totally taken effect. According to our prior patients, many do not suffer discomfort due to the numbing cream's effectiveness, while others experience slight pain with minimum discomfort.
        </p>
    </AccordionItemPanel>
</AccordionItem>



</Accordion>    
         
<br />
        
<Accordion allowZeroExpanded className="shadow">
        <AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How long is recovery? Will the Liquid Nose Job leave any marks or bruising?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        There is no requirement for recovery time after the Liquid Nose Job; you can return to your daily activities or job the same day if these activities do not involve any physical activity or sun exposure. <br /><br />

Following the treatment, you will notice instant results, with ultimate results taking two weeks to allow any mild swelling to subside and the nose to completely heal. As with any injectable-related adverse effects, redness at the injected site is typical and expected.<br /><br />

Because it is a non-invasive procedure, it is unlikely to leave any visible marks. During the consultation prior to the treatment, more details about what to expect during and after the treatment will be thoroughly explained.

      </p>
    </AccordionItemPanel>
</AccordionItem>





</Accordion>    
<br />
<Accordion allowZeroExpanded className="shadow">
 
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Is the Liquid Nose Job safe?     </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        The Liquid Nose Job involves injecting hyaluronic acid gel into the nose's soft tissue to shape, contour, and straighten it. Dermal fillers based on hyaluronic acid are made from natural sources rather than from animals. It's recognized as one of nature's most adaptable, and it's developed after considerable scientific testing. <br /> <br />

The fillers dissolve naturally in the body over time as the body is able to break them down, and the treatment results fade over time. The filler, like naturally occurring hyaluronic acid in the skin, breaks down and is reabsorbed by the body. Hyaluronic acid has established a reputation as a safe, effective, and cost-efficient alternative to surgery.


        </p>
    </AccordionItemPanel>
</AccordionItem>




</Accordion>    
<br /> 


        

<Accordion allowZeroExpanded className="shadow">
  
          <AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What happens 12-18 months after my liquid nose job?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Patients feel empowered after undergoing a liquid nose job since the results are immediate and natural-looking. Patients will notice a difference in their appearance soon after that. One of the greatest things about a liquid nose job is that it isn't a permanent solution, making it ideal for people who want to experiment with different looks without committing to surgery or entirely transforming their face. The nose can be modified as your face changes over time to sustain the result.<br /> <br />

The treatment's duration is determined by a number of factors, including the patient's metabolic rate, lifestyle factors, and nasal anatomy. As the face develops over time, tweaks can be made to keep the result consistent. After 10-12 months, the majority of patients return for a follow-up treatment. Some patients return after two years since the contour often maintains well, requiring less filler at this moment.<br /> <br />

This is a great illustration of how long a liquid nose job may endure. The initial photograph depicts the condition of the nose before to any treatments. The second photograph shows how the nose looks a year following treatment, when the filler has partially dissolved (note: it still has not fully gone back to its original form just like in the first picture). The ultimate effects can be seen in the third image, which was taken immediately after the procedure.

        </p> 
    </AccordionItemPanel>
</AccordionItem>

</Accordion>

<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Can the Liquid Nose Job make your nose look smaller?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        From the front and side profile, strategically injecting in the right locations of the nose can provide the idea of it being smaller. Fillers can be used to plump out spots on the nose to change its form, as well as change the angle of the nose and the way it reflects light. <br /> <br /> 
        
        This  is effective for making modest changes and tweaks to the nose rather than making significant changes such as physically lowering the nose's shape.

        </p>
    </AccordionItemPanel>
</AccordionItem>


</Accordion>  



 <br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How much is the liquid nose job UK?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        <b>The Liquid Nose Job is a set fee of £450 at Dermamina London. The patient is not restricted in terms of filler quantity; rather, the procedure focuses on delivering a complete, satisfactory results with no limitations on filler quantity.</b> <br /> <br />

        </p>
    </AccordionItemPanel>
</AccordionItem>


</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Is the Liquid Nose Job permanent?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        One of the favourite features about a liquid nose job is that it isn't a permanent solution, making it ideal for individuals who want to experiment with various looks without committing to surgery or entirely transforming their face. Regular dermal filler treatments to plump up and revitalise the appropriate parts of the face can help you retain your look as your face changes over time.

        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How do I prepare for a Liquid Nose Job?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        It's best to arrive for your appointment with your skin in the finest condition possible. This involves wearing less makeup on your face, having plenty of rest the night before so you can be comfortable and relaxed during the treatment, keeping hydrated, and eating enough. <br /> <br />Please arrive on time, complete all online forms, and also have reasonable expectations. Dermal fillers aren't designed to drastically transform your appearance; rather, they're intended to improve it.<br /> <br /> 

As soon as you make an appointment, you will receive pre-treatment guidelines, which will be included in your form. The following is a list of our pre-care recommendations:<br /> <br />

- For one week before your appointment, don't take any blood-thinning drugs like aspirin or ibuprofen. This is done in order to avoid bruising during and after the procedure. Before discontinuing any medicine, please consult a doctor. <br /> <br />

- Do not schedule an appointment if you have recently had or plan to have substantial dental work done. Root canal extraction and treatment, as well as deep filing, are included. It will be alright within two weeks.<br /> <br /> 

- Arnica tablets can be administered before and after treatment to help avoid bruising (optional) <br /> <br />

- Avoid drinking alcohol for 2-3 days before your visit to reduce the risk of bleeding and bruising.



        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What should I avoid after Liquid Nose Job?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
    It is critical that all patients follow the post-treatment instructions and report any adverse effects or concerns that they may have. Post-treatment instructions will be included in the forms sent to your email once a treatment has been scheduled to inform and assist you with any necessary preparations. <br /> <br />

To remind you, an after-care email is issued immediately after the treatment. We make sure you're safe during and after your treatment at Dermamina London. Following is some post-liquid nose job advice:<br /> <br />

- Do not exercise for 24 hours after receiving . <br /> <br />

- For the first few days, avoid any objects near the nose, including spectacles or sunglasses (avoid for 6-7 days), as well as additional pressure, touching, or rubbing of the injected areas. <br /> <br />

- Dental treatments should be postponed for the first few weeks after the procedure. This includes root canal treatment and extraction, as well as extensive filling. <br /> <br />

- For 24 hours, no alcohol or excessive salts. <br /> <br />

- Wait 12 hours before applying makeup after the treatment. <br /> <br />

- Avoid facials, laser treatments, and hot baths, saunas, and steam rooms.<br /> <br />

    </AccordionItemPanel>
</AccordionItem>

</Accordion>  



<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        What if I am unhappy with the Liquid Nose Job results?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        The Liquid Nose Job is a non-permanent procedure that allows you to experiment with different looks without committing to surgery or entirely altering your nose. Instead, it allows you to rejuvenate and enhance your natural charm while activating your own collagen in your body. <br /> <br />

With the administration of hyaluronidase, the treatment is totally reversible. This ensures the nose can be restored to its original condition in a matter of a few days. Patients who appreciate the results of the liquid nose job can have the treatment performed safely over time to keep the results.



      



        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Will I get a consultation before the liquid nose job?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
   

        Yes, every session starts with a detailed consultation. Mina, our excellent practitioner at Dermamina London, adopts a systematic approach to treatments, taking into account the patient's concerns and expectations, as well as providing support both before and after the procedure. <br /> <br />

Dermamina London believes that patient support commences with a pre-treatment consultation, whereby the patient is fully prepared and at ease in the hands of the practitioner, as well as a knowledge of the patient's desire and expectations. Involving the patient in collaborative decision-making and building a customised care plan are further examples of this. We want the patient to participate as much as possible.



        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  



<br /> 

<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Am I suitable for the Liquid Nose Job?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        At Dermamina, we strive for natural-looking outcomes that are personalised to each individual's unique needs, with the goal of enhancing rather than dramatically changing your image. <br /> <br />

Take a peek at the liquid nose job results results album to see how many distinct nose forms have been improved with dermal fillers' magic. If you're not sure whether the procedure will help you get the results you want, we recommend scheduling a consultation.<br /> <br />

You can also contact a member of the team and discuss your issues, specifying your nose shape and the goals you want to attain. It is critical to discuss your expectations with the practitioner during the appointment in order to confirm whether or not they are attainable.




        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  



<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Can I get a Liquid Nose Job before a Surgical Rhinoplasty?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Yes, if you're contemplating getting rhinoplasty but want to see how your nose might appear before making a significant choice, we recommend starting with a liquid nose job. A liquid nose operation can accomplish many of the same goals as a rhinoplasty. Make an appointment for a consultation today to learn more about what can be accomplished. <br /> <br />

If you've had a liquid nose job and are ready for a surgical rhinoplasty, wait until the fillers have totally dissolved before proceeding with the procedure. If you wish the filler to dissolve, we can help by using Hyaluronidase  to dissolve it. For further information, please contact the clinic.




        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Can the Liquid Nose Job be done after Surgical Rhinoplasty?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Yes, many people have come to us after being dissatisfied with the results of a rhinoplasty. We can help by injecting filler into the problem regions. After 7 months after rhinoplasty, a liquid nose job can be performed.



        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  



<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        How many sessions are required to complete the Liquid Nose Job?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Only one session is required. The liquid nose job is a non-invasive, 10-minute procedure for obtaining noticeable results right away. After that, you'll notice a huge difference in your nose.



        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  


<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Can I have other dermal filler treatments along with Liquid Nose Job?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        Yes, depending on the assessment and compatibility, many dermal filler treatments can be completed in one day. Did you know that we offer filler and liquid nose job packages? With professional and honest guidance from our wonderful practitioner, you can select to add a particular amount of filler in the desired areas.



        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion>  




<br /> 
<Accordion allowZeroExpanded className="shadow">
<AccordionItem>
    <AccordionItemHeading>
        <AccordionItemButton>
        Liquid Nose Job in London? Why choose Dermamina London?
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
        <p>
        When contemplating non-surgical therapies, it is critical to consult with a skilled and specialised practitioner who has extensive expertise and experience in this field. An competent and specialised practitioner will know exactly what to do to achieve the desired results while reducing risks, side effects, and difficulties. <br /> <br />

Dermamina London is known for producing outcomes that are both natural-looking and life-changing. We are dedicated to providing clinically sophisticated, evidence-based cosmetic treatments with the highest level of safety and honesty; we will not recommend any procedures if they are not appropriate for you.<br /> <br />

Check out our fantastic reviews given by our beloved patients, as well as our extensive portfolio of results all completed by our highly experienced, medically qualified, and most compassionate practitioner Mina.





        </p>
    </AccordionItemPanel>
</AccordionItem>

</Accordion> 

          </Col>




        </Row>



<br /> <br />
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment Online
                
                
                </Bookbutton> </a>
        


       


        </Container>
        </Section>

 


    );
}